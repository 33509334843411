<template>
  <div class="k-container">
    <div class="h-box-width img-container">
      <img :src="dataItem.img" width="80%" />
    </div>
    <h1 class="prod-title">{{ dataItem.title }}</h1>
    <p class="article-p">{{ dataItem.content }}</p>
    <Button class="add-star" type="primary" @click="show = true">提交意向单</Button>
    <div v-if="dataItem.params">
      <Divider orientation="left"><h2>技术参数</h2></Divider>
      <p class="article-p" v-html="dataItem.params.replace(/\n/g, '<br>')"></p>
    </div>
    <div v-if="dataItem.flow">
      <Divider orientation="left"><h2>工作流程</h2></Divider>
      <p class="article-p" v-html="dataItem.flow.replace(/\n/g, '<br>')"></p>
    </div>
    <div v-if="dataItem.images">
      <Divider orientation="left"><h2>配图</h2></Divider>
      <img v-for="url in dataItem.images" :key="url" :src="url" />
    </div>
    <Button class="add-star" type="primary" @click="show = true">提交意向单</Button>
    <prod-form :show="show" @onCancel="show = false" @onSubmit="handleAddStar"></prod-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { AddStar } from './apis/index';
import ProdForm from './prod-form.vue';

export default {
  name: 'prod-detail',
  props: {
    id: String,
  },
  data() {
    return {
      show: false,
    };
  },
  components: {
    ProdForm,
  },
  computed: {
    ...mapGetters('prod', {
      prodObj: 'prodObj',
    }),
    dataItem() {
      return this.prodObj[this.id];
    },
  },
  methods: {
    //ss
    handleAddStar(data) {
      const { iid, title, img, content } = { ...this.dataItem };
      const params = {
        prodName: `${title}(${iid})`,
        img,
        content,
        ...data,
      };
      AddStar(params).then(() => {
        this.show = false;
        this.$Message.success('提交成功!');
      });
    },
  },
};
</script>

<style lang="less" scoped>
.img-container {
  text-align: center;
}
.prod-title {
  margin: 20px 0;
}
.add-star {
  margin: 20px 0;
  float: right;
}
</style>
