<template>
  <div class="k-container">
    <Modal v-model="visible" title="提交意向单" footer-hide @on-cancel="cancel">
      <Form ref="formValidate" :model="formValidate" :rules="ruleValidate" :label-width="80">
        <FormItem label="姓名" prop="name">
          <Input v-model="formValidate.name" placeholder="请输入你的姓名"></Input>
        </FormItem>
        <FormItem label="手机号" prop="phone">
          <Input v-model="formValidate.phone" placeholder="请输入你的手机号"></Input>
        </FormItem>
        <FormItem label="E-mail" prop="email">
          <Input v-model="formValidate.email" placeholder="请输入你的邮箱"></Input>
        </FormItem>
        <FormItem label="机构" prop="group">
          <Input v-model="formValidate.group" placeholder="请输入你的公司名称"></Input>
        </FormItem>
        <FormItem>
          <Button type="primary" @click="handleOk('formValidate')">提交</Button>
          <Button @click="handleReset('formValidate')" style="margin-left: 8px">重置</Button>
        </FormItem>
      </Form>
    </Modal>
  </div>
</template>

<script>
export default {
  name: 'prod-form',
  props: {
    show: Boolean,
  },
  data() {
    return {
      visible: this.show,
      formValidate: {
        name: '',
        email: '',
        group: '',
        phone: '',
      },
      ruleValidate: {
        name: [{ required: true, message: '姓名不能为空', trigger: 'blur' }],
        email: [
          { required: true, message: '邮箱不能为空', trigger: 'blur' },
          { type: 'email', message: '错误的邮箱格式', trigger: 'blur' },
        ],
        phone: [
          { required: true, message: '手机号不能为空', trigger: 'blur' },
          { pattern: /^1[3456789]\d{9}$/, message: '手机号码格式不正确', trigger: 'blur' },
        ],
        group: [{ required: true, message: '公司名称不能为空', trigger: 'blur' }],
      },
    };
  },
  watch: {
    show(v) {
      this.visible = v;
    },
  },
  methods: {
    handleOk(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          this.$emit('onSubmit', this.formValidate);
        } else {
          this.$Message.error('提交失败!');
        }
      });
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    cancel() {
      this.$emit('onCancel');
    },
  },
};
</script>

<style lang="less" scoped>
.img-container {
  text-align: center;
}
.prod-title {
  margin: 20px 0;
}
.add-star {
  margin: 20px 0;
  float: right;
}
</style>
